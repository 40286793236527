import { Routes, Route } from "react-router"
import {
  Navigate, Outlet
} from "react-router-dom";

import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { MusicProvider } from "./contexts/MusicContext";
import {  
  LandingPage,
  SignUpPage,
  SignInPage,
  FeedPage,
  UploadPage,
  UserProfilePage,
  About,
  NotFound 
} from "./components/pages";
import { Header } from './components/organisms/Header';
import { PlayerBar } from "./components/organisms/PlayerBar";

const ProtectedRoute = () => {
  const { currentUser } = useAuth();
  return currentUser ? <Outlet /> : <Navigate replace to="/welcome" />
}

const Main = () => {
  return <MusicProvider>
    <Header />
    <Routes>
      <Route element={<ProtectedRoute />} >
        <Route path="/" element={<FeedPage />} />
        <Route path="/upload" element={<UploadPage />} />
        <Route path="/users/:uid" element={<UserProfilePage />} />
      </Route>
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </Routes>
    <PlayerBar />
  </MusicProvider>
}

const App = () => {

  return (
    <AuthProvider>
      <Routes>
        <Route path="/welcome" element={<LandingPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/not-found" element={<NotFound />}/>
        <Route path="/*" element={<Main />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
