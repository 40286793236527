import { createContext, useContext, useEffect, useState } from "react";
import { Logo } from "../components/atoms/Logo";
import axios from '../config/axios';

const MusicContext = createContext();

const useMusic = () => useContext(MusicContext);


const MusicProvider = ({ children }) => {
  const [tracks, setTracks] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [currentTrack, setCurrentTrack] = useState();
  const [currentProgress, setCurrentProgress] = useState();

  const fetchSoundTracks = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/music');
      setTracks(response?.data?.success)
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const getTracksByUID = (uid) => tracks?.filter(track => track?.author?._id === uid)

  useEffect(() => {
    fetchSoundTracks();
  }, [])
  
  const value = { 
    tracks,
    setTracks,
    error,
    loading ,
    fetchSoundTracks,
    getTracksByUID,
    currentTrack,
    setCurrentTrack,
    currentProgress,
    setCurrentProgress
  };

  if (loading) return <Logo className="loader loading"></Logo>

  return <MusicContext.Provider value={value}>
    {children}
  </MusicContext.Provider>
}

export { MusicProvider, useMusic };