import { createContext, useContext, useEffect, useState } from "react";

import axios from '../config/axios'

import { useNavigate } from 'react-router-dom'
import { Logo } from "../components/atoms/Logo";

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [error, setError] = useState();
  
  const checkLoginStatus = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get('/user/login');
      if (data.isConnected !== false)
        setCurrentUser(data);
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false)
    }
  } 

  const login = async (form) => {
    setLoading(true);
    try {
      const { data } = await axios.post('/user/login', form);
      setCurrentUser(data);
    } catch (error) {
      setError(error.message)
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    checkLoginStatus();
  }, [])

  useEffect(() => {
    if (currentUser) navigate('/')
  }, [currentUser])

  const value = { currentUser, login };

  if (loading) return <Logo className="loader loading"></Logo>

  return <AuthContext.Provider value={value}>
    {!error ? children :<p>{error}</p> }
  </AuthContext.Provider>
}

export { AuthProvider, useAuth };