import { useState } from 'react'
import { useNavigate } from 'react-router'

import { useAuth } from '../contexts/AuthContext'
import { useMusic } from '../contexts/MusicContext'

import axios from '../config/axios'

export const useUpload = () => {
    const { currentUser } = useAuth()
    const { setTracks } = useMusic()
    
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState()

    const navigate = useNavigate()

    const upload = async ({ title, audio }) => {
        const formData = new FormData()
        formData.append('title', title)
        formData.append('audio', audio)
        
        try {
            const { data: { success }} = await axios.post('/music/upload', formData, { 
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: ({ loaded, total }) => {
                    const percentage = Math.floor(loaded * 100 / total)
                    if (percentage < 100)
                        setProgress(percentage)                
                } 
            })
            setProgress(prev => success ? 100 : prev)
            setTracks(prev => [...prev, { ...success, author: currentUser }])
            navigate("/")
        } catch (error) {
            setError(error.message)
        }
    }

    return { upload, progress, error }
}
