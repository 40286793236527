import './index.css'

export const Logo = ({ className, ...rest }) => {

    return (
        <svg {...rest} className={"logo " + className} width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M28 6.41421V27C28 27.5523 27.5523 28 27 28H24H8H5C4.44772 28 4 27.5523 4 27V5C4 4.44772 4.44772 4 5 4H8C8.55228 4 9 4.44772 9 5V9.58579C9 9.851 9.10536 10.1054 9.29289 10.2929L9.70711 10.7071C9.89464 10.8946 10.149 11 10.4142 11H16H21H21.5858C21.851 11 22.1054 10.8946 22.2929 10.7071L22.7071 10.2929C22.8946 10.1054 23 9.851 23 9.58579V5C23 4.44772 23.4477 4 24 4H25.5858C25.851 4 26.1054 4.10536 26.2929 4.29289L27.7071 5.70711C27.8946 5.89464 28 6.149 28 6.41421ZM7 24V16C7 15.4477 7.44772 15 8 15H24C24.5523 15 25 15.4477 25 16V24C25 24.5523 24.5523 25 24 25H8C7.44772 25 7 24.5523 7 24Z" />
            <path className="mover" d="M21 4H18C17.4477 4 17 4.44772 17 5V9C17 9.55228 17.4477 10 18 10H21C21.5523 10 22 9.55228 22 9V5C22 4.44772 21.5523 4 21 4Z" />
        </svg>
    )
}
