import { useState, useEffect } from 'react';

import axios from '../config/axios';

const useAxios = ({ url, method, body = null, headers = null }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setloading] = useState(true);

  const fetchData = async () => {
    try {
      const { data } = await axios[method](url, JSON.parse(headers), JSON.parse(body));
      setData(data)
    } catch (error) {
      setError(error.message)
    } finally {
      setloading(false)
    }
  };

  useEffect(() => {
    fetchData();
  }, [method, url, body, headers]);

  return { data, error, loading };
};

export default useAxios;